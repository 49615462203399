const blue = '#0089d0';
const green = '#a2c61c';
const gray = '#ccc';
const darkGray = '#999';
const white = '#fff';
const black = '#333'

export default {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992
  },
  colors: {
    blue,
    gray,
    darkGray,
    green,
    black,
    white
  },
  border: gray,
  background: '#f7f7f7'
}
