import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

import theme from '../theme/csu'

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  body {
    background-color: ${props => props.theme.background};
    font-family: 'Lato', Arial, Helvetica, sans-serif;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.blue};
  }
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Layout